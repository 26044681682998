import React from 'react';
import { Link } from 'gatsby';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    sectionBox,
    container,
    categories,
    categoryLink,
    title,
    date,
    leadImage,
    desktopLeadImage,
    desktopLeadImageRatio,
    mobileLeadImage,
    mobileLeadImageRatio,
    lead,
    leadText,
    markdownBox,
    markdown,
    tags,
    author,
    imageHolder,
    caption as captionClass,
} from './video.module.scss';
import { relations } from '../../config/relations';
import { ISection } from '../../models/section.model';
import { IVideo } from '../../models/video.model';
import CameraIcon from '../../assets/images/svg/camera.svg';

import Title from '../atoms/title';
import Subtitle from '../atoms/subtitle';
import Markdown from '../hoc/markdown';
import Section from '../hoc/section';
import TagChipList from '../molecules/tag-chip-list';
import createImageHolder from '../../utils/create-image-holder';

export interface IVideoSection extends ISection {
    extendedItems: {
        video: IVideo;
    };
}

interface IVideoHeaderProps {
    className?: string;
    section: IVideoSection;
    TitleTag?: React.ElementType;
}

const Video: React.FC<IVideoHeaderProps> = ({ className = '', section, TitleTag = 'h2' }) => {
    const { video } = section.extendedItems;
    const { sectionId, css, style } = section;

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${sectionBox}`}
            css={css}
            style={style}
            classes={{ container }}
        >
            <Subtitle className={categories}>
                {video.mainCategory && (
                    <Link className={categoryLink} to={video.mainCategory.pathname}>
                        {video.mainCategory.title}
                    </Link>
                )}
            </Subtitle>
            <Title className={title} Tag={TitleTag}>
                {video.title}
            </Title>
            <time className={date}>{video.displayPublishedAt}</time>
            <div className={leadImage}>
                <Image
                    className={desktopLeadImage}
                    media={video.media}
                    ratioClass={desktopLeadImageRatio}
                    relation={relations.mainImage}
                    showCaption
                    renderCaption={renderCaption}
                />
                <Image
                    className={mobileLeadImage}
                    media={video.media}
                    ratioClass={mobileLeadImageRatio}
                    relation={relations.mainImageMobile}
                    showCaption
                    renderCaption={renderCaption}
                />
            </div>
            <div className={lead}>
                <strong className={leadText}>{video.lead}</strong>
            </div>
            <div className={markdownBox}>
                {video.content && (
                    <Markdown
                        className={markdown}
                        components={{
                            p: ({ children, node }) => {
                                return createImageHolder({
                                    children,
                                    nodeChildren: node.children[0],
                                    Tag: node.tagName,
                                    className: imageHolder,
                                });
                            },
                            figure: ({ children, node }) => {
                                return createImageHolder({
                                    children,
                                    nodeChildren: node.children[0],
                                    Tag: node.tagName,
                                    className: imageHolder,
                                    style: node.properties?.style ? node.properties?.style : undefined,
                                });
                            },
                            figcaption: ({ children }) => renderCaption(children),
                        }}
                    >
                        {video.content}
                    </Markdown>
                )}
            </div>
            {video.tags && video.tags.length > 0 && <TagChipList className={tags} tags={video.tags} />}
            {/*{video.author && <Author className={author} author={video.author} NameTag="h4" />}*/}
        </Section>
    );
};

function renderCaption(caption: React.ReactNode) {
    return (
        <figcaption className={captionClass}>
            <CameraIcon /> {caption}
        </figcaption>
    );
}

export default Video;
