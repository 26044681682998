// extracted by mini-css-extract-plugin
export var author = "video-module--author--4115a";
export var caption = "video-module--caption--60c8c";
export var categories = "video-module--categories--10fba";
export var categoryLink = "video-module--category-link--35850";
export var container = "video-module--container--18760";
export var date = "video-module--date--10142";
export var desktopLeadImage = "video-module--desktop-lead-image--452c0";
export var desktopLeadImageRatio = "video-module--desktop-lead-image-ratio--b9885";
export var imageHolder = "video-module--image-holder--bbe05";
export var lead = "video-module--lead--71208";
export var leadImage = "video-module--lead-image--eb7a2";
export var leadText = "video-module--lead-text--c0f31";
export var markdown = "video-module--markdown--2d3a5";
export var markdownBox = "video-module--markdown-box--51c6e";
export var mobileLeadImage = "video-module--mobile-lead-image--8fb7d";
export var mobileLeadImageRatio = "video-module--mobile-lead-image-ratio--787fa";
export var sectionBox = "video-module--section-box--af6c3";
export var tags = "video-module--tags--a7680";
export var title = "video-module--title--07143";